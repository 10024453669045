.pdp-main {
    // End Slider

    // Product Overview
    @include breakpoint(small only) {
        .product-badge {
            margin: 0 0 15px;
        }

        .tested-bykal-badge {
            margin: 0 0 30px;
        }
    }

    .psresults-wrapper {
        padding-top: 0;
    }

    .brand-name {
        font-size: rem-calc(16);
        margin: 0 0 0.3125rem;
    }

    .product-name {
        font-size: rem-calc(22);
        margin: 0;
    }

    .pdp-top-name {
        @include breakpoint(medium up) {
            margin-bottom: 2rem;

            .product-name {
                font-size: rem-calc(26);
            }
        }
    }

    .product-set-list {
        @include clearfix;

        .product-set-item {
            @include clearfix;

            margin-bottom: 10px;

            .quantity {
                float: right;
                max-width: 75px;
                min-width: 75px;

                @include breakpoint(large up) {
                    float: left;
                }
            }
        }

        .product-set-details {
            float: left;
            width: 70%;

            @include breakpoint(large up) {
                width: 100%;
            }

            .product-price {
                width: max-content;
            }
        }

        .add-sub-product {
            float: left;
            width: 30% !important;

            @include breakpoint(small only) {
                margin: 8px 0 0 !important;
            }

            @include breakpoint(medium only) {
                margin: 8px 0 0 !important;
            }

            @include breakpoint(large up) {
                margin-top: 15px !important;
                width: 100% !important;
            }
        }
    }

    .add-all-to-cart {
        margin-top: 0;
        width: 100%;
    }

    .set-attr-titles {
        @include clearfix;

        min-width: 200px;

        text-align: right;


        .value-front {
            @include clearfix;
            display: inline-block;
            font-size: rem-calc(14);
            padding: 9px 12px;

            @include breakpoint(medium up) {
                font-size: rem-calc(16);
            }
        }

        .value-rear {
            @include clearfix;
            display: inline-block;

            font-size: rem-calc(14);
            min-width: 80px;
            padding: 9px 12px;

            @include breakpoint(medium up) {
                font-size: rem-calc(16);
                min-width: 200px;
            }
        }
    }


    .manufacturer-number {
        @include font-gotham-bold;
        color: map-get($foundation-palette, midgrey);

        font-size: rem-calc(11);
        margin: 5px 0 0;
    }

    .multiple-manufacturer-numbers {
        margin: 0;
    }

    .title-main-section {
        @include font-gotham-bold;
        border-left: 3px solid map-get($foundation-palette, primary);

        font-size: rem-calc(18);
        margin: 0 0 25px;
        padding: 0 0 0 18px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: rem-calc(24);
        }

        &.review-title {
            margin: 0 0 20px;
        }
    }

    .product-attributes {
        .attribute {
            font-size: rem-calc(14);
            line-height: 1;
            margin: 0;

            @include breakpoint(medium up) {
                font-size: rem-calc(16);
            }
        }

        .name {
            @include font-gotham-medium;
        }

        .value {
            @include font-gotham-book;

            text-transform: capitalize;
        }
    }

    .overview,
    .product-description,
    .test-results,
    .product-sizes,
    .rating-box {
        margin-bottom: 100px;
    }

    // Slider
    .product-image-container {
        @include breakpoint(small only) {
            padding-bottom: 20px;
            padding-top: 30px;
        }
    }

    .product-thumbnails {
        float: left;
        position: relative;
        top: -7px;
        width: 50px;

        @include breakpoint(medium up) {
            width: 56px;
        }

        @include breakpoint(large up) {
            top: -10px;
            width: 66px;
        }

        .slick-slider {
            margin: 0;
            text-align: center;
            visibility: hidden;

            &.slick-initialized {
                visibility: visible;
            }
        }

        .thumb {
            a {
                display: inline-block;
                margin: 0.375rem 0;

                @include breakpoint(medium up) {
                    margin: 0.515rem 0;
                }

                @include breakpoint(large up) {
                    margin: 0.595rem 0;
                }
            }

            img {
                border: 1px solid map-get($foundation-palette, light-gray);
            }
        }

        .slick-next,
        .slick-prev {
            color: transparent;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            width: 100%;
            width: 16px;

            &::before {
                color: #000;
                font-family: "fontello";
            }

            &.slick-disabled::before {
                color: #bfc0c2;
            }
        }

        .slick-next {
            bottom: -30px;
            top: auto;

            &::before {
                content: "\e80f";
            }
        }

        .slick-prev {
            top: - 10px;

            &::before {
                content: "\e80e";
            }
        }

        .slick-slide {
            &.selected {
                .productthumbnail {
                    border: 1px solid map-get($foundation-palette, primary);
                }
            }
        }
    }

    .product-primary-image {
        border: 1px solid map-get($foundation-palette, light-gray);
        float: right;
        min-height: 232px;
        text-align: center;
        width: calc(100% - 63px);

        @include breakpoint(medium up) {
            min-height: 272px;
            width: calc(100% - 70px);
        }

        @include breakpoint(large up) {
            min-height: 317px;
            width: calc(100% - 85px);
        }

        .slick-track {
            width: auto !important;
        }

        .slick-slide {
            width: 100% !important;
        }

        .product-image {
            cursor: default;
            display: inline-block;
            max-width: 230px;

            @include breakpoint(medium up) {
                max-width: 270px;
            }

            @include breakpoint(large up) {
                max-width: 315px;
            }
        }
    }

    .overview {
        .product-attributes {
            .attribute {
                font-size: rem-calc(14);
                margin: 5px 0;

                &.trailer-tire-attribute {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }

            &.category-attributes {
                .name {
                    float: left;
                    margin-right: 5px;
                    min-height: 18px;
                    overflow: hidden;
                }
            }

            .name {
                @include font-gotham-bold;
            }

            .category {
                color: map-get($foundation-palette, midgrey);
            }
        }

        .product-review {
            margin: 25px 0;
        }

        .aditional-details {
            @include font-gotham-book;

            font-size: rem-calc(14);
            margin: 0 0 20px;

            @include breakpoint(medium only) {
                padding-right: 0.625rem;
            }
        }
    }

    // Price Qty and Add to cart sections
    .price-right-box {
        width: 100%;

        @include breakpoint(medium only) {
            padding-left: 0.625rem;
            width: 50%;
        }
    }

    .product-price-addtocart {
        @include breakpoint(medium only) {
            margin: 40px 0 0;
        }

        .product-price-wrap {
            float: left;
            margin-bottom: 15px;
            width: 100%;

            @include breakpoint(large up) {
                margin: 0 0 25px;
                width: 100%;
            }

            .price-label {
                margin: 0 0 9px;
            }
        }

        .price-right-box {
            @include breakpoint(small only) {
                position: relative;
            }

            .inventory {
                @include breakpoint(small only) {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }

        .inventory {
            float: right;

            @include breakpoint(large up) {
                float: left;
                width: 100%;
            }

            input {
                display: none;
                margin: 0;
            }

            .product-quantity {
                @include breakpoint(large up) {
                    max-width: 75px;
                    width: 50%;
                }
            }
        }

        #totalPriceSection {
            @include breakpoint(small only) {
                margin-top: 14px;
                padding-bottom: 15px;
            }

            .quantity {
                @include breakpoint(large up) {
                    margin-bottom: 15px;
                }
            }

            .product-price {
                display: inline;
                font-size: rem-calc(25);

                .currency-indices {
                    font-size: rem-calc(13);
                }
            }

            .price-standard {
                color: map-get($foundation-palette, primary-accessible);
            }
        }

        .product-add-to-cart {
            float: left;
            margin: 25px 0 0;
            width: 100%;

            @include breakpoint(medium only) {
                display: flex;
                justify-content: space-between;
            }

            @include breakpoint(small up) {
                margin: 0;
            }

            .button {
                @include breakpoint(small only) {
                    margin: 0;
                    width: 100%;
                }
            }
        }

        .price-label,
        .quantity .quantity-total,
        .quantity label {
            @include font-gotham-bold;
            color: map-get($foundation-palette, midgrey);
            display: inherit;
            font-size: rem-calc(11);
            line-height: 1;
            margin-bottom: 9px;
            text-transform: uppercase;
        }
    }

    .product-price {
        @include font-gotham-bold;

        font-size: rem-calc(35);
        line-height: 1;

        .currency-indices {
            font-size: rem-calc(17);
        }

        .price-sales {
            color: map-get($foundation-palette, secondary);
            margin: 0 10px 0 0;
        }

        .price-standard {
            color: map-get($foundation-palette, primary-accessible);

            + .price-sales {
                @include font-gotham-medium;
                color: map-get($foundation-palette, midgrey);

                font-size: rem-calc(13);
                text-decoration: line-through;

                .currency-indices {
                    font-size: rem-calc(13);
                }
            }
        }

        .rebate {
            @include font-gotham-medium;

            color: map-get($foundation-palette, primary);
            display: block;
            font-size: rem-calc(12);
            margin: 10px 0 0;

            @include breakpoint(medium only) {
                margin-bottom: 10px;
            }
        }
    }

    // Product description
    .product-description {
        .long-description {
            font-size: rem-calc(14);
            margin: 0 0 80px;

            @include breakpoint(medium up) {
                margin: 0;
                padding: 0 35px 0 0;
            }

            @include breakpoint(large up) {
                font-size: rem-calc(16);
            }
        }

        .care-plan {
            .title {
                @include font-gotham-bold;

                font-size: rem-calc(16);
                margin: 0 0 15px;
            }
        }
    }

    // Kal's test results
    .test-results {
        .driver-notes-wrap {
            margin: 0 0 30px;
        }

        .driver-notes {
            @include font-gotham-book;
            font-size: rem-calc(18);

            font-style: italic;
        }

        .driver-name {
            @include font-gotham-book;
            color: map-get($foundation-palette, midgrey);

            font-size: rem-calc(12);
        }

        .testing-video-box {
            margin-bottom: 35px;

            @include breakpoint(medium up) {
                order: 2;
            }
        }

        .questions {
            margin: 15px 0 0;

            p {
                @include font-gotham-medium;

                font-size: rem-calc(13);
                margin: 0 0 10px;
            }

            .inline-question-mark {
                align-items: center;
                color: map-get($foundation-palette, midgrey);
                display: flex;
                margin: 0;
            }

            .questionmarkcircle {
                color: map-get($foundation-palette, primary);
                margin: 0 10px 0 0;
                top: 0;
            }
        }

        .season-title {
            float: left;
            font-size: rem-calc(20);
            width: 100%;
        }

        .attribute-title {
            font-size: rem-calc(16);
            margin: 0 0 7px;
        }

        .seasson {
            @include breakpoint(large up) {
                padding-right: 2.5rem;
            }

            .section {
                margin: 0 0 35px;

                @include breakpoint(medium up) {
                    float: left;
                    width: 47%;

                    &:nth-child(odd) {
                        float: right;
                    }
                }

                &:last-child {
                    margin: 0;
                }
            }

            ul {
                margin: 0;
            }

            li {
                @include clearfix;
                @include font-gotham-book;

                line-height: 1;
                margin: 5px 0;
            }

            .label-title {
                float: left;
                width: 50%;

                @include breakpoint(large up) {
                    width: 70%;
                }
            }

            .label-title-bold {
                float: left;
                font-weight: 700;
                width: 50%;

                @include breakpoint(large up) {
                    width: 70%;
                }
            }

            .raiting {
                color: map-get($foundation-palette, primary);
                display: flex;
                float: right;
                justify-content: flex-end;
                width: 50%;

                @include breakpoint(large up) {
                    width: 30%;
                }
            }

            [class^="icon-"]::before,
            [class*=" icon-"]::before {
                margin: 0;
            }

            i {
                float: left;
            }
        }
    }

    // Sizes & Specs
    .product-sizes {
        .model-specs {
            .model-box {
                @include font-gotham-medium;

                font-size: rem-calc(16);
                margin: 0 0 20px;
            }

            .model-size {
                @include font-gotham-book;
            }
        }

        .view-all {
            @include font-gotham-bold;
            display: inline-block;

            font-size: rem-calc(13);
            margin: 0 0 30px;

            &::before {
                content: "\f053";
                font-family: fontawesome;
                font-size: 7px;
                padding-right: 0.3125rem;
                vertical-align: middle;
            }
        }

        .product-attributes {
            .attribute {
                padding: 9px 12px;

                &:nth-child(odd) {
                    background: map-get($foundation-palette, light-gray);
                }

                &:nth-child(even) {
                    padding: 12px;
                }

                &.finish_attribute .value:not(.value-rear) {
                    display: none;
                }
            }

            .value {
                float: right;
            }

            .value-rear {
                min-width: 80px;
                text-align: right;

                @include breakpoint(medium up) {
                    min-width: 200px;
                }

                .name {
                    display: none;
                }
            }
        }

        .load-range-title {
            margin-top: 40px;
        }
    }

    //style if product is service type
    .service-info-wrapper {
        .left-info {
            .sel-subtitle {
                strong {
                    @include font-gotham-bold;
                }
            }
        }

        .right-info {
            .newsearch-tireselection {
                .init-selector {
                    align-self: center;
                    margin: 0;
                }
            }
        }

        .service-info-content {
            display: flex;
            flex-wrap: wrap;

            @include breakpoint(medium up) {
                flex-wrap: nowrap;
            }

            .sel-subtitle {
                flex: 0 0 100%;

                @include breakpoint(medium up) {
                    flex: 0 0 auto;
                    font-size: rem-calc(15);
                }
            }

            .tire {
                padding-right: 15px;
            }
        }

        .sel-info {
            padding: 20px 0;

            @include breakpoint(medium up) {
                padding: 10px 0;
            }
        }
    }
}

// Sizes & Specs - master product
.product-size-tabs {
    align-items: stretch;
    border: 0;
    display: flex;
    flex-direction: row;
    flex-grow: initial;
    flex-wrap: wrap;

    @include breakpoint(small only) {
        display: block;
    }

    &.trailer-load-range-tabs {
        display: block;
    }

    .tabs-title {
        align-items: center;
        border-bottom: 3px solid map-get($foundation-palette, light-gray);
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        max-width: 25%;
        text-align: center;
        width: auto;

        @include breakpoint(small only) {
            display: block;
            flex: 0 1 auto;
            width: 25%;
        }

        @include breakpoint(large up) {
            max-width: 22%;
        }

        a {
            @include font-gotham-bold;
            color: map-get($foundation-palette, midgrey);
            font-size: 16px;

            padding: 0.5rem 1rem 1rem;

            &::after {
                content: "\f10e";
                font-family: fontawesome;
                font-size: 6px;
                vertical-align: super;
            }

            &.load-range-anchor {
                padding: 0.5rem 2rem 1rem;

                &::after {
                    content: none;
                }
            }
            
        }

        > a:focus,
        > a[aria-selected="true"] {
            background: transparent;
        }

        &.is-active,
        &:focus,
        &:hover {
            border-bottom-color: map-get($foundation-palette, primary);

            a,
            a:hover {
                color: map-get($foundation-palette, secondary);
            }
        }
    }
}

.product-size-tabs-content {
    border: 0;

    .tabs-panel {
        padding: 2.75rem 0;

        a {
            @include font-gotham-book;

            color: map-get($foundation-palette, secondary);
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            li {
                flex-basis: 100%;
                line-height: 48px;

                @include breakpoint(medium up) {
                    flex-basis: 33%;
                    line-height: 30px;
                }
            }
        }

        &.trailer-load-range-options {
            padding-top: 0.5rem;

            &.is-active {
                display: flex;
            }

            .product-size-tabs {
                flex-grow: 1;
            }

            .tabs-title {
                max-width: 65px;
            }
        }

        .tire-size-options {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            @include breakpoint(medium up) {
                width: 70%;
            }

            .trailer-tire-size {
                margin-bottom: 25px;
                width: 100%;

                @include breakpoint(medium up) {
                    margin-bottom: 0;
                    width: auto;
                }

                &:hover {
                    background: map-get($foundation-palette, light-gray);

                    a {
                        color: map-get($foundation-palette, primary);
                    }
                }
            }
        }
    }
}

.image-tooltip {
    color: map-get($foundation-palette, primary);
    float: right;
    font-size: 12px;
    margin: 5px 0 0;

    @include breakpoint(medium up) {
        float: left;
        margin: 5px 0 0 20px;
    }

    .has-tip {
        border: 0;
        font-weight: normal;
    }
}

.tooltip.img-tip {
    @include font-gotham-book;

    background: map-get($foundation-palette, white-default);
    box-shadow: 0 0 55px -16px rgba(0, 0, 0, 0.75);
    color: map-get($foundation-palette, secondary);
    font-size: 12px;
    max-width: 260px !important;
    min-width: 230px !important;
    padding: 0;

    h1 {
        @include font-gotham-medium;
        background: map-get($foundation-palette, primary);
        color: map-get($foundation-palette, white-default);

        font-size: 13px;
        line-height: 1rem;
        margin: 0;
        padding: 7px 15px;
    }

    p {
        @include font-gotham-book;

        font-size: 12px;
        margin: 0;
        padding: 15px;
    }

    &.top {
        &::before {
            border: inset 0.45rem;
            border-color: map-get($foundation-palette, white-default) transparent transparent transparent;
            border-top-style: solid;
        }
    }
}

// Master PDP
.pdp-master {
    .product-sizef,
    .product-wheelsizef {
        display: none;
    }

    .product-price {
        .rebate {
            br {
                display: none;
            }
        }
    }
}

@include breakpoint(medium up) {
    .pdp-main {
        // Product Overview
        .badges-box {
            justify-content: flex-start;
            margin-bottom: 15px;
            margin-top: 10px;

            p {
                justify-content: flex-end;
            }
        }

        .product-badge,
        .tested-bykal-badge {
            margin: 0;
        }

        .tested-bykal-badge {
            padding: 7px;
        }

        .top-info-product-box {
            margin: 0 0 10px;
        }
    }
}

@include breakpoint(large up) {
    // PDP navigation - just for large
    .pdp-nav {
        margin: 0 0 70px;
        width: 100%;

        ul {
            margin: 0;
        }

        li {
            @include font-gotham-bold;
            border-bottom: 6px solid map-get($foundation-palette, light-gray);
            float: left;
            font-size: rem-calc(16);
            text-align: center;

            width: 20%;

            a {
                color: map-get($foundation-palette, midgrey);
                display: inline-block;
                padding: 0 0 15px;
                width: 100%;
            }

            &:hover,
            &.active {
                border-bottom: 6px solid map-get($foundation-palette, primary);

                a {
                    color: #000;
                }
            }
        }
    }

    .pdp-main {
        .product-sizes {
            .attr-column {
                float: left;
                width: 45%;

                &:nth-child(2) {
                    float: right;
                }
            }
        }
    }
}
